import React, { Suspense, lazy, useEffect, useState } from "react";
import {
  query,
  collection,
  where,
  orderBy,
  onSnapshot,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { auth, db } from "./firebase-config";
import {
  ChakraProvider,
  useDisclosure,
  ColorModeScript,
} from "@chakra-ui/react";
import { Routes, Route } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import theme from "./theme";
import Loading from "./pages/Loading";
// import Posts from "./components/posts";
// import Post from "./components/post";
const HowItWorks = lazy(() => import("./pages/HowItWorks"));
const Login = lazy(() => import("./pages/Login"));
const Pricing = lazy(() => import("./pages/Pricing"));
const Register = lazy(() => import("./pages/Register"));
const Sign = lazy(() => import("./pages/Sign"));
const Kiosk = lazy(() => import("./pages/Kiosk"));
const Counter = lazy(() => import("./pages/Counter"));
const DashboardHome = lazy(() => import("./pages/Dashboard/DashboardHome"));
const DashboardKiosk = lazy(() => import("./pages/Dashboard/DashboardKiosk"));
const DashboardSign = lazy(() => import("./pages/Dashboard/DashboardSign"));
// const Maintenance = lazy(() => import("./pages/Maintenance"));
const DashboardCounter = lazy(() =>
  import("./pages/Dashboard/DashboardCounter")
);
const DashboardMarketing = lazy(() =>
  import("./pages/Dashboard/DashboardMarketing")
);
const DashboardNotifications = lazy(() =>
  import("./pages/Dashboard/DashboardNotifications")
);
const Home = lazy(() => import("./pages/Home"));
// const getImages = async () => {
//   const url = "https://unsplash.tedfish.workers.dev";
//   const resp = await fetch(url, {
//     method: "POST",
//     body: JSON.stringify({ query: "dog" }),
//     headers: {
//       "Content-Type": "application/json",
//     },
//   });
//   return resp.json();
// };
function App() {
  // getImages().then((data) => console.log(data));
  const [tabIndex, setTabIndex] = useState(0);
  const [demoBusiness, setDemoBusiness] = useState(
    "A3XD83kiUbUkcQUYeX3u7qcj2T63"
  );
  const [businessName, setBusinessName] = useState("");
  const [industry, setIndustry] = useState("Restaurants & Bars");
  const [business, setBusiness] = useState({});
  const [businessId, setBusinessId] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [numberList, setNumberList] = useState([]);
  const [outreachStyle, setOutreachStyle] = useState([]);
  const [outreachStyleFiltered, setOutreachStyleFiltered] = useState([]);
  const [user, loading] = useAuthState(auth);
  const [pulled, setPulled] = useState(false);
  const [pulledNumber, setPulledNumber] = useState(1);
  const {
    isOpen: isCustomizeOpen,
    onOpen: onCustomizeOpen,
    onClose: onCustomizeClose,
  } = useDisclosure();
  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure();
  // set initial business state
  useEffect(() => {
    if (!business.colorPrimary) {
      setBusiness({
        ...business,
        bgUrl:
          "https://firebasestorage.googleapis.com/v0/b/take-a-number-8a68f.appspot.com/o/files%2Fphoto%201569350080887.avif?alt=media&token=0064ba50-1e04-4a94-aa38-c74cbb6c6315",
        colorPrimary: "green",
        colorSecondary: "blue",
        displayCounterBgImage: 0,
        displayCounterLogo: 1,
        displayCounterNowServing: 1,
        displayCounterVisualQueue: 1,
        displayCounterWaitlist: 1,
        displayCounterWaitStats: 1,
        displayKioskBgImage: 1,
        displayKioskDisclaimer: 1,
        displayKioskLogo: 1,
        displayKioskName: 1,
        displayKioskNumbers: 1,
        displayNotificationsUnsubscribe: 1,
        displayKioskNowServing: 1,
        displayKioskPooledPrize: 0,
        displayKioskPromo: 0,
        displayKioskRewards: 1,
        displayKioskSMS: 1,
        displayKioskVisualQueue: 0,
        displayKioskWaitlist: 0,
        displayKioskWaitStats: 0,
        displayNotificationsPulledNumber: 1,
        displayQrCode: 1,
        displaySignBackgroundImage: 1,
        displaySignNowServing: 1,
        displayVisualQueue: 1,
        displayWaitlist: 1,
        displayWizard: 1,
        inputKioskButton: "Join the list",
        inputKioskInputTitle: "Join the list!",
        inputKioskPromoTitle: "Promo",
        inputNotificationsTitle: "Welcome to [name]",
        inputKioskRewardsTitle: "Join our rewards program!",
        logoUrl:
          "https://firebasestorage.googleapis.com/v0/b/take-a-number-8a68f.appspot.com/o/files%2FKadenceWP%20Logo.svg?alt=media&token=afb1d630-bfcb-4eff-97fc-5e5420d02bff",
        roundedCorners: 1,
        sliderValue: 1,
        promos: [
          { code: "save10", message: "Get 10% off your next purchase!" },
          {
            code: "freeapp",
            message: "Free appetizer with purchase of an entree",
          },
          {
            code: "freedessert",
            message: "Free dessert with purchase of an entree",
          },
        ],
        // promoMessages: [
        //   // ?????
        //   { 1: "Welcome to [name]. We'll let you know when it's your turn." },
        // ],
      });
    }
  }, [business]);
  useEffect(() => {
    const getUser = async (uid) => {
      const docRef = doc(db, "users", uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setBusinessId(docSnap.id);
        setBusiness(docSnap.data());
        setIsLoaded(true);
      } else {
        console.log("No such document!");
      }
    };
    user?.uid ? getUser(user?.uid) : getUser(demoBusiness);
  }, [user?.uid, demoBusiness]);
  // update business
  useEffect(() => {
    const updateBusiness = async () => {
      try {
        await updateDoc(doc(db, "users", businessId), business);
        // console.log("Document successfully updated!", business.colorPrimary);
      } catch (error) {
        console.log("Error updating document:", error);
      }
    };
    isLoaded && updateBusiness();
  }, [business, isLoaded, businessId]);
  useEffect(() => {
    // query numbers
    const q = query(
      collection(db, "numbers"),
      where("businessId", "==", businessId),
      // where("timestampServed", "==", null),
      orderBy("number", "desc")
    );
    const numbersSnapshot = onSnapshot(q, (querySnapshot) => {
      setNumberList([]);
      querySnapshot.docs.forEach((doc) => {
        setNumberList((prev) => [...prev, { ...doc.data() }]);
      });
    });
    // query outreach styles
    const qQ = query(collection(db, "frequencies"), orderBy("hours", "asc"));
    const outreachStyleSnapshot = onSnapshot(qQ, (querySnapshot) => {
      setOutreachStyle([]);
      querySnapshot.docs.forEach((doc) => {
        setOutreachStyle((prev) => [...prev, { ...doc.data() }]);
      });
    });
    if (loading) return;
    return () => {
      numbersSnapshot();
      outreachStyleSnapshot();
    };
  }, [businessId, loading, user, user?.uid, business.sliderValue]);
  // create filtered list of outreach styles
  useEffect(() => {
    const filteredArray = outreachStyle.filter(
      (item) => item.sliderValue <= business.sliderValue
    );
    setOutreachStyleFiltered(filteredArray);
  }, [outreachStyle, business.sliderValue]);
  // wait types
  useEffect(() => {
    business.waitType === "0" &&
      setBusiness((business) => ({
        ...business,
        displayKioskNowServing: 1,
        displayKioskWaitlist: 0,
      }));
    business.waitType === "1" &&
      setBusiness((business) => ({
        ...business,
        displayKioskNowServing: 0,
        displayKioskWaitlist: 1,
      }));
  }, [business.waitType]);
  document.title = numberList.length
    ? `Serving: ${
        numberList
          .filter((number) => number.timestampServed === null)
          .slice(-1)[0].number
      } | Golden Wait | A Better Way to Wait`
    : "Golden Wait | A Better Way to Wait";
  return (
    <ChakraProvider theme={theme}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <Suspense fallback={<Loading />}>
        <Routes>
          {/* <Route
            path="/"
            element={
              <Maintenance
                user={user}
                business={business}
                setBusiness={setBusiness}
                businessId={businessId}
                numberList={numberList}
                outreachStyle={outreachStyle}
                outreachStyleFiltered={outreachStyleFiltered}
                pulled={pulled}
                setPulled={setPulled}
                pulledNumber={pulledNumber}
                setPulledNumber={setPulledNumber}
              />
            }
          /> */}
          {/* <Route path="/posts" element={<Posts />} />
          <Route path="/posts/:id" element={<Post />} /> */}
          <Route
            path="/"
            element={
              <Home
                user={user}
                business={business}
                setBusiness={setBusiness}
                businessId={businessId}
                setBusinessId={setBusinessId}
                numberList={numberList}
                outreachStyle={outreachStyle}
                outreachStyleFiltered={outreachStyleFiltered}
                pulled={pulled}
                setPulled={setPulled}
                pulledNumber={pulledNumber}
                setPulledNumber={setPulledNumber}
                demoBusiness={demoBusiness}
                setDemoBusiness={setDemoBusiness}
                tabIndex={tabIndex}
                setTabIndex={setTabIndex}
                industry={industry}
                setIndustry={setIndustry}
                businessName={businessName}
                setBusinessName={setBusinessName}
                isDemo={true}
              />
            }
          />
          <Route
            path="how-it-works"
            element={
              <HowItWorks
                user={user}
                business={business}
                numberList={numberList}
                pulled={pulled}
                setPulled={setPulled}
                pulledNumber={pulledNumber}
                setPulledNumber={setPulledNumber}
                demoBusiness={demoBusiness}
                setDemoBusiness={setDemoBusiness}
                tabIndex={tabIndex}
                setTabIndex={setTabIndex}
                setBusiness={setBusiness}
                outreachStyle={outreachStyle}
                outreachStyleFiltered={outreachStyleFiltered}
                industry={industry}
                setIndustry={setIndustry}
                businessName={businessName}
                setBusinessName={setBusinessName}
                isDemo={true}
              />
            }
          />
          <Route
            path="login"
            element={
              <Login
                user={user}
                business={business}
                setBusiness={setBusiness}
                businessId={businessId}
                numberList={numberList}
                demoBusiness={demoBusiness}
                setDemoBusiness={setDemoBusiness}
                industry={industry}
                setIndustry={setIndustry}
              />
            }
          />
          <Route
            path="start"
            element={
              <Register
                user={user}
                business={business}
                setBusiness={setBusiness}
                businessId={businessId}
                numberList={numberList}
                demoBusiness={demoBusiness}
                setDemoBusiness={setDemoBusiness}
                businessName={businessName}
                setBusinessName={setBusinessName}
                industry={industry}
                setIndustry={setIndustry}
                isDemo={true}
              />
            }
          />
          <Route
            path="pricing"
            element={
              <Pricing
                user={user}
                business={business}
                businessId={businessId}
                numberList={numberList}
                demoBusiness={demoBusiness}
                setDemoBusiness={setDemoBusiness}
                industry={industry}
                setIndustry={setIndustry}
              />
            }
          />
          <Route
            path="sign"
            element={
              <Sign
                user={user}
                business={business}
                businessId={businessId}
                numberList={numberList}
              />
            }
          />
          <Route
            path="kiosk"
            element={
              <Kiosk
                user={user}
                business={business}
                businessId={businessId}
                numberList={numberList}
                pulled={pulled}
                setPulled={setPulled}
                pulledNumber={pulledNumber}
                setPulledNumber={setPulledNumber}
              />
            }
          />
          <Route
            path="counter"
            element={
              <Counter
                user={user}
                business={business}
                businessId={businessId}
                numberList={numberList}
              />
            }
          />
          <Route
            path="dashboard"
            element={
              <DashboardHome
                user={user}
                setBusiness={setBusiness}
                business={business}
                numberList={numberList}
                outreachStyle={outreachStyle}
                outreachStyleFiltered={outreachStyleFiltered}
                isCustomizeOpen={isCustomizeOpen}
                onCustomizeOpen={onCustomizeOpen}
                onCustomizeClose={onCustomizeClose}
                isModalOpen={isModalOpen}
                onModalOpen={onModalOpen}
                onModalClose={onModalClose}
              />
            }
          />
          <Route
            path="dashboard/kiosk"
            element={
              <DashboardKiosk
                user={user}
                setBusiness={setBusiness}
                business={business}
                numberList={numberList}
                pulledNumber={pulledNumber}
                setPulledNumber={setPulledNumber}
                pulled={pulled}
                setPulled={setPulled}
                tabIndex={tabIndex}
                setTabIndex={setTabIndex}
                isCustomizeOpen={isCustomizeOpen}
                onCustomizeOpen={onCustomizeOpen}
                onCustomizeClose={onCustomizeClose}
                isModalOpen={isModalOpen}
                onModalOpen={onModalOpen}
                onModalClose={onModalClose}
              />
            }
          />
          <Route
            path="dashboard/sign"
            element={
              <DashboardSign
                user={user}
                setBusiness={setBusiness}
                business={business}
                numberList={numberList}
                isCustomizeOpen={isCustomizeOpen}
                onCustomizeOpen={onCustomizeOpen}
                onCustomizeClose={onCustomizeClose}
                isModalOpen={isModalOpen}
                onModalOpen={onModalOpen}
                onModalClose={onModalClose}
              />
            }
          />
          <Route
            path="dashboard/counter"
            element={
              <DashboardCounter
                user={user}
                setBusiness={setBusiness}
                business={business}
                numberList={numberList}
                pulledNumber={pulledNumber}
                setPulledNumber={setPulledNumber}
                pulled={pulled}
                setPulled={setPulled}
                isCustomizeOpen={isCustomizeOpen}
                onCustomizeOpen={onCustomizeOpen}
                onCustomizeClose={onCustomizeClose}
                isModalOpen={isModalOpen}
                onModalOpen={onModalOpen}
                onModalClose={onModalClose}
                tabIndex={tabIndex}
                setTabIndex={setTabIndex}
              />
            }
          />
          <Route
            path="dashboard/notifications"
            element={
              <DashboardNotifications
                user={user}
                setBusiness={setBusiness}
                business={business}
                numberList={numberList}
                isCustomizeOpen={isCustomizeOpen}
                onCustomizeOpen={onCustomizeOpen}
                onCustomizeClose={onCustomizeClose}
                isModalOpen={isModalOpen}
                onModalOpen={onModalOpen}
                onModalClose={onModalClose}
              />
            }
          />
          <Route
            path="dashboard/marketing"
            element={
              <DashboardMarketing
                user={user}
                setBusiness={setBusiness}
                business={business}
                numberList={numberList}
                outreachStyle={outreachStyle}
                outreachStyleFiltered={outreachStyleFiltered}
                isCustomizeOpen={isCustomizeOpen}
                onCustomizeOpen={onCustomizeOpen}
                onCustomizeClose={onCustomizeClose}
                isModalOpen={isModalOpen}
                onModalOpen={onModalOpen}
                onModalClose={onModalClose}
              />
            }
          />
        </Routes>
      </Suspense>
    </ChakraProvider>
  );
}
export default App;
