import { extendTheme } from "@chakra-ui/react";
// Supports weights 100-900
import "@fontsource-variable/mulish";
import { mode } from "@chakra-ui/theme-tools";
const theme = extendTheme({
  colors: {
    yellow: {
      50: "#FCF8E8",
      100: "#F7ECC0",
      200: "#F1DF97",
      300: "#ECD36F",
      400: "#E7C746",
      500: "#E2BA1D",
      600: "#ffd700",
      700: "#877012",
      800: "#5A4B0C",
      900: "#2D2506",
    },
    green: {
      50: "#F0F4F2",
      100: "#D5E2D9",
      200: "#BACFC1",
      300: "#9FBCA9",
      400: "#94B49F",
      500: "#699678",
      600: "#547860",
      700: "#3F5A48",
      800: "#2A3C30",
      900: "#151E18",
    },
    orange: {
      50: "#FBF0E9",
      100: "#F5D5C2",
      200: "#EEBA9B",
      300: "#E7A074",
      400: "#ECB390",
      500: "#DA6A25",
      600: "#AE551E",
      700: "#834016",
      800: "#572A0F",
      900: "#2C1507",
    },
    red: {
      50: "#FBEDEA",
      100: "#F3CCC4",
      200: "#EBAC9D",
      300: "#E48B77",
      400: "#DF7861",
      500: "#D44A2B",
      600: "#AA3B22",
      700: "#7F2C1A",
      800: "#551E11",
      900: "#2A0F09",
    },
  },
  Links: {
    baseStyle: {
      "&:hover": { textDecoration: "none" },
    },
  },
  fonts: {
    heading: `Mulish Variable`,
    body: `Mulish Variable`,
  },
  styles: {
    global: (props) => ({
      body: {
        bg: mode("white", "green.600")(props),
      },
    }),
  },
});
export default theme;
