import React from "react";
import { Flex, Spinner } from "@chakra-ui/react";
function Loading() {
  return (
    <Flex
      h={"100vh"}
      // bg="gray.600"
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Spinner
        size="xl"
        color="yellow.600"
        thickness="10px"
        speed="2.5s"
        emptyColor="white"
      />
    </Flex>
  );
}
export default Loading;
